"use server";

import { getProformaInvoices } from "../apis/server_apis";
import { ProcurementProformaInvoicesResponse } from "../types/order";

export const getProcurementProformaInvoicesRouteData =
  async (): Promise<ProcurementProformaInvoicesResponse> => {
    const response = await getProformaInvoices();
    return response;
  };
